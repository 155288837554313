import { useState } from 'react';
import workData from '../data/works.json';

//components
import '../global.css';
import MonetModal from '../components/MonetModal';
import GetTraindModal from '../components/GetTraindModal';
import Navigation from '../components/Navigation';
import FooterCentered from '../components/FooterCentered';

//libraries
import { motion } from "framer-motion";

const works = workData.map((work) => ({
    ...work,
    imageSrc: require(`../media/${work.imageSrc}`),
}));

export default function Works() {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [selectedWork, setselectedWork] = useState(null);

    const handleEmail = (e) => {
        e.preventDefault();
        console.log(email);
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="bg-white">
                <Navigation />
                <div className="mx-auto min-w-full py-16 sm:px-6 sm:py-24">
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5">
                        {works.map((work) => (
                            <div
                                key={work.id}
                                className="group relative flex flex-col overflow-hidden bg-white transition duration-500 ease-in-out transform hover:-translate-y-1"
                                onClick={() => {
                                    setselectedWork(work);
                                    setOpen(true);
                                }}
                            >
                                <div className="aspect-h-3 aspect-w-3 sm:aspect-none group-hover:opacity-75 sm:h-auto">
                                    <a href={work.href} target="_blank" rel="noopener noreferrer">
                                        <img
                                            src={work.imageSrc}
                                            alt={work.imageAlt}
                                            className="p-1 h-full w-full mx-auto sm:h-full sm:w-full"
                                        />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <MonetModal open={selectedWork?.name === 'Monet.ai'} setOpen={setselectedWork} email={email} setEmail={setEmail} handleEmail={handleEmail} />
                <GetTraindModal open={open && selectedWork && selectedWork.name === 'GetTraind'} setOpen={setOpen} selectedWork={selectedWork} />
                <div className="my-40"></div>
                <FooterCentered />
            </div>
        </motion.div>
    )
}
