import { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';

export default function GetTraindModal({ open, setOpen, selectedWork }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Transition show={!!(open && selectedWork && selectedWork.name === 'GetTraind')}
            as={Fragment}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 flex items-center justify-center z-50" onClick={() => setOpen(false)}>
                <div className="bg-white p-4 rounded shadow-lg" onClick={(e) => e.stopPropagation()}>
                    <iframe
                        width={windowWidth > 1600 ? 1600 : windowWidth * 0.8}
                        height="900"
                        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FETYLX3FvIDVz7OCJbrMNGE%2FGet-Traind%3Ftype%3Ddesign%26node-id%3D444-31805%26t%3DxzA5sto6oVX1git9-1%26scaling%3Dmin-zoom%26page-id%3D444%253A31804%26starting-point-node-id%3D444%253A31805%26mode%3Ddesign"
                        allowFullScreen
                    />
                    <button onClick={() => setOpen(false)}>Close</button>
                </div>
            </div>
        </Transition>
    );
}