import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import MonetVideo from "../media/videos/Monet-Demo-v2.mp4";

export default function MonetModal({ open, setOpen, email, setEmail, handleEmail }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(null)}>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6 relative">
                                <button
                                    type="button"
                                    className="absolute top-0 right-0 p-4 text-gray-400 hover:text-gray-500"
                                    onClick={() => setOpen(null)}
                                >
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    <span className="sr-only">Close</span>
                                </button>

                                <Dialog.Title as="h3" className="text-center text-3xl leading-6 font-cooper-black-std text-vivid-cyan py-4">
                                    Monet.ai
                                </Dialog.Title>
                                <div className="mt-2">
                                    <div className="flex flex-col md:flex-row">
                                        <div className="md:w-2/3">
                                            <video src={MonetVideo} autoPlay loop muted className="rounded">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div className="text-center md:w-1/2 mt-4 md:mt-0 md:ml-4">
                                            <p className="mt-2 text-sm text-gray-500">
                                                Monet.ai is a Gen-AI art-to-print platform that revolutionizes home decor & print-ready art. Monet leverages several state-of-the-art generative AI models, to offer unique artwork.<br />
                                                <br />
                                                With development ongoing, we're excited to bring this idea to life and share it with you very soon. You can stay up to date with updates below!
                                            </p>
                                            <form onSubmit={handleEmail}>
                                                <div>
                                                    <label htmlFor="email" className="flex text-sm font-cooper-black-std leading-6 text-gray-900">
                                                        Email
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="Please enter your email"
                                                            aria-describedby="email-description"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                                                        We'll only use this for serious updates.
                                                    </p>
                                                </div>
                                                <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-4 text-center text-md text-gray-900">
                                    Coming Soon
                                </p>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}