import React, { useEffect, useRef } from 'react';
import Navigation from '../components/Navigation';
import FooterCenteredTransparent from '../components/FooterCenteredTransparent';
import CTA from '../components/CTA';
import { elementVariantEarly } from '../helpers/animationVariants';
import { DotLottie } from '@lottiefiles/dotlottie-web';

import { motion } from "framer-motion";

export default function Contact() {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            const dotLottie = new DotLottie({
                autoplay: true,
                loop: true,
                canvas: canvasRef.current,
                src: "https://lottie.host/1870be1f-3e6d-465b-b0a4-778f67f86e22/jyPpTIqTus.json", // or .json file
            });
        }
    }, []);

    return (
        <div className="relative isolate bg-white">
            <Navigation />
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#a5dff0] to-[#a6e7ff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-7xl pt-36 sm:pt-28 lg:pt-44">
                <div className="text-center">
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                        className="mx-auto max-w-2xl"
                    >
                        <p className="font-cooper-black-std pt-20 md:pt-28 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Send Us A Message Anytime 💬</p>
                        <p className="mt-6 text-md sm:text-lg leading-8 text-gray-600">
                            For any & all inquiries, please reach out to <a href="mailto:hello@bigweblabs.com" className="text-vivid-cyan font-semibold hover:underline">hello@bigweblabs.com</a>
                        </p>
                    </motion.div>

                </div>
            </div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.5 } }}
                exit={{ opacity: 0 }}
                className="flex justify-center items-center pb-40"
            >
                <canvas ref={canvasRef} id="maillottie-canvas" style={{ width: '300px', height: '300px' }}></canvas>
            </motion.div>
            <div
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#a5dff0] to-[#a6e7ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <CTA />
            <FooterCenteredTransparent />
        </div>
    )
}