import testimonialsData from '../data/testimonials.json';
import { elementVariantEarly } from '../helpers/animationVariants';

//images
import Kathleen from '../media/images/Kathleen-Fariss.png';
import FarrisCoachingLogo from '../media/images/Fariss-Coaching-Logo.png';

//libraries
import { motion } from "framer-motion";

const featuredTestimonial = {
  body: 'I had the pleasure of collaborating with JD and the team at Big Web Labs on various projects, including the development of the inaugural website for The Kindness Games. I was impressed by their customer-focused approach, kindness, attention to detail, and responsiveness. I wholeheartedly recommend them to anyone seeking to establish their brand in the digital landscape.',
  author: {
    name: 'Coach Kathleen Fariss',
    handle: 'Founder, Fariss Coaching and Consulting',
    imageUrl: Kathleen,
    logoUrl: FarrisCoachingLogo,
  },
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const testimonials = testimonialsData.map((columnGroup) =>
  columnGroup.map((column) =>
    column.map((testimonial) => ({
      ...testimonial,
      author: {
        ...testimonial.author,
        imageUrl: require(`../media/${testimonial.author.imageUrl}`),
      },
    }))
  )
);

export default function Testimonials() {
  return (
    <div className="relative isolate bg-white pb-32 pt-20">
      <div
        className="absolute inset-x-0 -z-10 transform-gpu overflow-hidden blur-3xl"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#a5dff0] to-[#a6e7ff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <p className="font-cooper-black-std mt-2 text-3xl tracking-tight text-gray-900 sm:text-4xl">
            We work with amazing people
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={elementVariantEarly}
              viewport={{ once: true, amount: 0 }}
              className="flex-auto"
            >
              <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
                <p>{`“${featuredTestimonial.body}”`}</p>
              </blockquote>
            </motion.div>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <img
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
                src={featuredTestimonial.author.imageUrl}
                alt=""
              />
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                variants={elementVariantEarly}
                viewport={{ once: true, amount: 0 }}
                className="flex-auto"
              >
                <div className="font-semibold">{featuredTestimonial.author.name}</div>
                <div className="text-gray-600">{`${featuredTestimonial.author.handle}`}</div>
              </motion.div>
              <img className="h-10 w-auto flex-none" src={featuredTestimonial.author.logoUrl} alt="" />
            </figcaption>
          </figure>
          {testimonials.map((columnGroup, columnGroupIdx) => (
            <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={classNames(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                      ? 'xl:row-span-2'
                      : 'xl:row-start-1',
                    'space-y-8'
                  )}
                >
                  {column.map((testimonial) => (
                    <figure
                      key={testimonial.author.handle}
                      className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                    >
                      <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                      >
                        <blockquote className="text-gray-900">
                          <p>{`“${testimonial.body}”`}</p>
                        </blockquote>
                      </motion.div>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.author.imageUrl} alt="" />
                        <motion.div
                          initial="offscreen"
                          whileInView="onscreen"
                          variants={elementVariantEarly}
                          viewport={{ once: true, amount: 0 }}
                          className="flex-auto"
                        >
                          <div className="font-semibold">{testimonial.author.name}</div>
                          <div className="text-gray-600">{`${testimonial.author.handle}`}</div>
                        </motion.div>
                      </figcaption>
                    </figure>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}