import {
    CloudArrowUpIcon,
    PaintBrushIcon,
    PencilIcon,
    LightBulbIcon,
    ServerIcon,
} from '@heroicons/react/20/solid';
import servicesData from '../data/services.json';
import { elementVariantEarly } from '../helpers/animationVariants';

//libraries
import { motion } from "framer-motion";

const icons = {
    CloudArrowUpIcon,
    PaintBrushIcon,
    PencilIcon,
    LightBulbIcon,
    ServerIcon,
};

const services = servicesData.map((service) => ({
    ...service,
    icon: icons[service.icon],
}));

export default function Services() {
    return (
        <div className="relative isolate bg-white pt-12 pb-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                    className="mx-auto max-w-2xl text-center sm:text-center"
                >
                    <p className="font-cooper-black-std mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Services 🧑‍💻</p>
                    <p className="mt-6 text-md sm:text-lg leading-8 text-gray-600">
                        Building Your Digital Future: Where Custom Designs Meet Powerful Code.
                    </p>
                </motion.div>
                <div className="mx-auto mt-16 max-w-7xl sm:mt-20 md:mt-24 lg:px-8">
                    <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-md sm:text-lg leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-x-8 lg:gap-y-16">
                        {services.map((service) => (
                            <motion.div
                                key={service.name}
                                className="relative pl-9"
                                whileHover={{ scale: 1.1 }}
                                initial="offscreen"
                                whileInView="onscreen"
                                variants={elementVariantEarly}
                                viewport={{ once: true, amount: 0 }}
                            >
                                <dt className="font-cooper-black-std text-gray-900">
                                    <service.icon className="absolute left-1 top-1 h-5 w-5 text-vivid-cyan" aria-hidden="true" />
                                    {service.name}
                                </dt>{' '}
                                <dd className="inline">{service.description}</dd>
                            </motion.div>
                        ))}
                    </dl>
                </div>
            </div>

        </div>
    )
}
