export const elementVariant = {
    offscreen: {
        y: 250,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "tween",
            duration: 1.0,
            ease: "easeInOut"
        }
    }
};

export const elementVariantLater = {
    offscreen: {
        y: 300,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "tween",
            duration: 1.4,
            ease: "easeInOut"
        }
    }
};

export const elementVariantEarly = {
    offscreen: {
        y: 50,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "tween",
            duration: 0.6,
            ease: "easeInOut"
        }
    }
};