import { useEffect, useState } from "react";
import { AwesomeButton } from "react-awesome-button";
import "../awesomebuttonstyles.scss";
import "../App.css";
import BigWebLabsLogo from '../media/images/Big-Web-Labs-Logo.png';
import LogRocket from 'logrocket';

function Stealth() {
    LogRocket.init('uxwxpv/big-web-labs');

    const [isEarlyAccessBtnShown, setIsEarlyAccessBtnShown] = useState(true);
    const [isPassphraseShown, setIsPassphraseShown] = useState(false);

    useEffect(() => {
        if (!isEarlyAccessBtnShown) {
            setIsPassphraseShown(true);
        }
    }, [isEarlyAccessBtnShown]);

    function ButtonOne() {
        const [isVisible, setIsVisible] = useState(true);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (!isVisible) {
                setIsLoading(true);
                setTimeout(() => {
                    setIsEarlyAccessBtnShown(false);
                    setIsLoading(false);
                }, 1000);
            }
        }, [isVisible]);

        const handleButtonClick = () => {
            setIsVisible(false);
        };

        return (
            <div
                className={`fade-transition ${isVisible ? "opacity-100" : "opacity-0"}`}
            >
                <AwesomeButton
                    type="primary"
                    onPress={handleButtonClick}
                    style={{ cursor: isLoading ? "progress" : "default" }}
                >
                    {isLoading ? "Loading..." : "Early Access Code?"}
                </AwesomeButton>
            </div>
        );
    }

    function ButtonTwo() {
        const [isVisible, setIsVisible] = useState(true);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (!isVisible) {
                setIsLoading(true);
                setTimeout(() => {
                    setIsPassphraseShown(false);
                    setIsEarlyAccessBtnShown(true);
                    setIsLoading(false);
                }, 1000);
            }
        }, [isVisible]);

        const handleButtonClick = () => {
            setIsVisible(false);
        }

        return (
            <div
                className={`fade-transition ${isVisible ? "opacity-100" : "opacity-0"}`}
            >
                <div className="flex justify-center">
                </div>
                <div className="flex justify-center gap-x-2">
                    <AwesomeButton
                        type="primary"
                        onPress={handleButtonClick}
                        style={{ cursor: isLoading ? "progress" : "default" }}
                    >
                        {isLoading ? "Loading..." : "Back"}
                    </AwesomeButton>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-very-dark-blue min-h-screen">
            <div className="">
                <div className="">
                    <div className="pt-40">
                        <img src={BigWebLabsLogo}
                            alt="Big Web Labs Logo"
                            className="floating m-auto w-[200px] lg:w-[250px] select-none"
                        />
                        <h1 className="text-center hover:cursor-crosshair text-3xl text-very-light-gray md:text-4xl">Big Web LAbs</h1>
                        <div className="md:flex justify-center text-center hover:cursor-crosshair">
                            <p className="text-very-light-gray pr-1 text-xs">
                                We're in stealth, and we'll be back soon.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pb-20">
                    {isEarlyAccessBtnShown && (
                        <div className="flex justify-center pt-8">
                            <ButtonOne />
                        </div>
                    )}
                    {isPassphraseShown && (
                        <div className="pt-4">
                            <ButtonTwo />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Stealth;