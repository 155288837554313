const navigation = {
    main: [
        { name: 'Home', href: '/' },
        { name: 'About', href: '/about' },
        { name: 'Clients', href: '/works' },
        { name: 'Contact', href: '/contact' },
    ],
    secondary: [
        { name: 'Terms', href: '/terms' },
        { name: 'Privacy', href: '/privacy' },
    ],
}

export default function FooterCenteredTransparent() {
    return (
        <footer>
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="mx-auto w-2/4 border-t border-gray-400" />
                </div>
            </div>
            <div className="mx-auto overflow-hidden px-6 pt-16 sm:pt-24 lg:px-8 bg-transparent">
                <nav className="text-center justify-center flex sm:justify-center gap-x-4 sm:gap-x-6" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="pb-6">
                            <a href={item.href} className="text-sm leading-6 text-gray-600 hover:no-underline hover:text-bright-magenta">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                <p className="mt-10 text-center text-sm leading-5 text-gray-500">
                    &copy; Till time ends. Big Web Labs. All rights reserved.
                </p>
                <nav className="text-center justify-center flex sm:justify-center gap-x-4 sm:gap-x-6" aria-label="Footer">
                    {navigation.secondary.map((item) => (
                        <div key={item.name}>
                            <a href={item.href} className="text-xs leading-6 text-gray-600 hover:no-underline hover:text-bright-magenta">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
            </div>
        </footer>
    )
}
