//components
import Navigation from '../components/Navigation';
import HomepageHeroSection from '../components/HomepageHeroSection';
import FeaturedWorksList from '../components/FeaturedWorksList';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import CTA from '../components/CTA';
import FooterCentered from '../components/FooterCentered';
import ParticlesBackground from "../components/ParticlesBackground";

import { motion } from "framer-motion";

export default function Home() {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <ParticlesBackground id="tsparticles" />
            <Navigation />
            <HomepageHeroSection />
            <FeaturedWorksList />
            <Services />
            <Testimonials />
            <CTA />
            <FooterCentered />
            <div className="mb-40 sm:mb-60"></div>
        </motion.div>
    )
}
