//images
import Logo from '../media/images/Big-Web-Labs-Logo.png';

//libraries
import { motion } from 'framer-motion';

function Terms() {

    const Navbar = () => {
        return (
            <header className="absolute inset-x-0 top-0 z-50 mx-auto">
                <nav className="flex items-center justify-between p-6 lg:px-8 shadow-bottom-outline-vivid-cyan" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/">
                            <img
                                className="h-10 w-auto"
                                src={Logo}
                                alt="Logo of Big Web Labs, featuring stylized blue letter 'W' with a modern, angular design."
                            />
                        </a>
                    </div>
                </nav>
            </header>
        );
    }

    const Footer = () => {
        return (
            <nav className="shadow-outline-vivid-cyan py-5 pl-[4%] text-sm">
                <a href="/" className="hover:no-underline hover:text-bright-magenta">Return to Homepage</a>
            </nav>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Navbar />
            <div className="mt-32 pb-5 font-cooper-black-std text-3xl text-center"><h1>Terms of Use</h1></div>
            <div className="m-auto max-w-[90%] 2xl:max-w-[80%] 3xl:max-w-[50%] text-sm my-4 italic">Last Updated: April 8, 2024.</div>
            <div className="m-auto max-w-[90%] 2xl:max-w-[80%] 3xl:max-w-[50%] text-md">
                <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client," "You," and "Your" refers to the company or person in contract with Big Web Labs or person accessing this website, using Big Web Labs' services and accepting the company's terms and conditions. "The Company," "Ourselves," "Big Web Labs," "We," and "Us" refer to our company. "Party," "Parties," or "Us" refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the express purpose of meeting the Client's needs in respect of the provision of the company's stated services/products, in accordance with and subject to prevailing United States law. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they are taken as interchangeable and therefore as referring to the same.  <strong>IF YOU DO NOT AGREE TO THE TERMS AND THIS PRIVACY POLICY YOU MAY NOT ACCESS OR OTHERWISE USE THE SERVICE.</strong>

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Assignment of Project</strong>
                    <div className="my-4"></div>
                    Big Web Labs reserves the right to assign subcontractors to this project to ensure that the terms of this agreement are met. The client agrees to the use of third-party or open-source technologies or frameworks for this project by Big Web Labs without prior written or verbal consent by the client.

                    <div className="my-4"></div>
                    <strong class="font-cooper-black-std">Privacy and Confidentiality</strong>
                    <div class="my-4"></div>
                    <p>Big Web Labs is committed to protecting your privacy and maintaining the confidentiality of your information. Information collected from clients is used only by authorized employees on a need-to-know basis. We comply with the Data Protection Act 1998 and ensure that your information will not be disclosed to third parties except as required by law or to our partners/suppliers for the provision of our services.</p>
                    <br />
                    <p>Personal information will not be used for unsolicited communications without your agreement. Clients have the right to request sight of, and copies of all client records we keep, subject to reasonable notice.</p>


                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Disclaimer Exclusions and Limitations:</strong>
                    <div className="my-4"></div>
                    The information on this website is provided on an 'as is' basis. To the fullest extent permitted by law, Big Web Labs:<br />
                    <br />
                    <li>Excludes all representations and warranties relating to this website and its contents or which are or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the company's literature; and</li>
                    <li>Excludes all liability for damages arising out of or in connection with the use of our provided software/website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised Big Web Labs of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs, and the data thereon or any other direct or indirect, consequential, and incidental damages.</li>
                    <li>Big Web Labs does not exclude liability for death or personal injury caused by its negligence. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.</li>

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Description of Service</strong>
                    <div className="my-4"></div>
                    Big Web Labs designs, develops websites, web applications, software, and mobile apps. The client understands that Big Web Labs' services may include certain communications from Big Web Labs such as advertisements, notices, service announcements, and newsletters. The client is responsible for obtaining access to Big Web Labs' services that may involve third-party fees (including but not limited to ISP, merchant accounts, and gateways). The client is also responsible for all equipment and software necessary to access Big Web Labs' services.

                    <div className="my-4"></div>
                    <strong class="font-cooper-black-std">Scope of Work</strong>
                    <div class="my-4"></div>
                    <p>The scope of work for website/software development is detailed in the client's proposal and Project Specifications Document. It includes the agreed-upon number of pages, features, and functionality. Any requests for additional features or functionality beyond the initial agreement will be subject to a new proposal and additional charges.</p>
                    <br />
                    <p>Clients are entitled to two weeks of free revisions upon project completion. Further modifications will require a new service proposal and may incur additional costs.</p>


                    <div className="my-4"></div>
                    <strong class="font-cooper-black-std">Electronic Delivery Policy</strong>
                    <div class="my-4"></div>
                    <p>Big Web Labs communicates with clients electronically, which includes the delivery of notices, agreements, and other important communications. Clients consent to receive such electronic communications in the following ways:</p>
                    <li>To the email address provided at the time of sale.</li>
                    <li>To any new email address set up by the client through Big Web Labs.</li>
                    <p>Clients are responsible for regularly checking their designated email addresses for communications. Notices are deemed effective when sent.</p>


                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Call Monitoring and Recording Privacy Statement</strong>
                    <div className="my-4"></div>
                    Big Web Labs may monitor and record phone calls answered by Big Web Labs and made by Big Web Labs, as well as archive recorded voicemail messages. This is for strictly internal use and training of BWL staff, and is not released to the public. Big Web Labs records calls for training purposes, to improve customer service, and to ensure an accurate record of client calls, which may be needed to support transactions that take place over the phone. This allows Big Web Labs to identify how we can better serve our customers.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Software Development Procedure</strong>
                    <div className="my-4"></div>
                    The software project development process at Big Web Labs involves an initial sales intake call and the assembly of a scope of work (SoW) in collaboration with the client. This is followed by design, development, and marketing sprints, as well as revisions, iterations, and maintenance as needed.<br />
                    <br />
                    Before work may begin on a project, the client must sign the project acceptance agreement and electronically accept these terms of service. Big Web Labs works hand-in-hand with the client to develop a scope of work that meets their needs and expectations.<br />
                    <br />
                    Big Web Labs will not be held liable for accuracy of information, typos, or spelling errors in any of the content approved by the client and published on the website. The client will be notified by email when the various milestones have been completed.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Completion Timeframes</strong>
                    <div className="my-4"></div>

                    Big Web Labs does not guarantee a specific timeline for the completion of any website or software project. The client agrees that there is no strict timeline and that there will be no penalties associated with a late delivery. However, if the client hinders the timeline by not providing necessary copy or materials, Big Web Labs reserves the right to extend the timeline or, in extreme cases, terminate the contract.<br />
                    <br />
                    Factors that may influence the completion date of a project include, but are not limited to, the complexity of the project, the availability of Big Web Labs personnel, and the accounting status of the client's account. If the client does not respond to communications from Big Web Labs and, as a result, the project is unable to be started or completed, the client is still responsible for all fees incurred, including but not limited to the design purchase price, set-up fees, enhancements purchased, and monthly hosting charges that begin accruing from the date of sale. If the website or software requires custom programming, functionality, flash, e-commerce, or the use of a database, the overall development time will be extended.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Payments & Chargebacks</strong>
                    <div className="my-4"></div>

                    Big Web Labs accepts payment by bank check, personal check, money order, and major credit/debit cards (Master Card, Visa, American Express). Prior authorization from Big Web Labs is required before using a credit card for payment. Payment is expected to be made promptly according to the terms of any development project. If payment is overdue, Big Web Labs reserves the right to charge the client's credit card on file for the unpaid balance. Big Web Labs also reserves the right to waive any chargeback rights for transactions and to follow contract resolution procedures.<br />
                    <br />
                    A deposit of 30% - 50% of the total project payment is typically required for most development work. If payment has not been received within 15 days of the due date, a follow-up email will be sent. After 30 days of non-payment, the contract may be terminated. If the client wishes to resume the project after termination, they may do so by paying the balance of the invoice and a reinstatement fee of up to 20% of the total project cost. It is not possible to refund a deposit or any project payment after 30 days or once Big Web Labs has sent the design or development source code work to the client or loaded it onto their server. Big Web Labs reserves the right to determine whether a refund is applicable if requested by the client for any reason. If a refund is provided, any disbursements already paid to third parties by Big Web Labs on behalf of the client (such as hosting fees, domain name registration, and software purchases) will not be refundable. If paid by credit card, refunds will be issued to the original credit card used at the time of purchase. If an account has been cancelled by Big Web Labs due to a breach of the terms and conditions by the client, the client will not be eligible for a refund.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Cancellation Fee and Refund Policy</strong>
                    <div className="my-4"></div>

                    <li>A minimum of a 50% cancellation fee will be retained by Contractor on cancelled account even if no work has been started and no content yet submitted by the Client.</li>
                    <li>A minimum of a 75% cancellation fee will be retained by Contractor on cancelled accounts if work has been presented to the Client; or Contractor has made multiple attempts to work with the Client, and Client has not responded to those attempts.</li>
                    <li>A 100% cancellation fee will be retained by Contractor and NO REFUND issued if any changes and/or modifications requested by the Client have been completed by Contractor. No Refund will be issued on any website / software cancelled after services have been rendered, including but not limited to, the design work and programming having been completed and/or the software has been moved to client’s server or transferred to their procession.</li>
                    <li>100% cancellation fee will be retained and NO REFUND will be issued by Contractor if Client cancels after 30 days from the initial sale.</li>

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Minimum Cancellation Fee</strong>
                    <div className="my-4"></div>
                    Client agrees that a minimum cancellation fee of 50% will be retained by Big Web Labs on all cancelled accounts even if no work has been started. The cancellation fee is charged to compensate Big Web Labs for up-front expenses and services rendered, including but not limited to, time costs incurred for project meetings and analysis, project management, documentation, design and development, employee expenses and overhead costs.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Enhancement Sales</strong>
                    <div className="my-4"></div>
                    A 100% cancellation fee will be retained by Big Web Labs and NO REFUND will be issued once work has begun on any enhancements purchased, including, but not limited to, databases, programming, logos, flash, galleries, rollovers, e-commerce stores, security certificates, design time, domain names, etc. A minimum cancellation fee of 50% will be retained on cancelled expedited services. Client agrees that a minimum cancellation fee of 50% will be retained by Big Web Labs on all cancelled enhancement purchases if cancelled within 30 days of the enhancement sale and if work has not yet begun. NO REFUND will be issued by Big Web Labs if Client cancels after 30 days from the enhancement sale. Enhancements or additional services purchased after the initial sale are separate purchases and are in addition to and separate from the original sale. If a client cancels an enhancement the original sale is not cancelled.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Monthly Maintenance</strong>
                    <div className="my-4"></div>
                    Big Web Labs offers monthly maintenance packages to its clients in order to ensure the proper functioning and security of their software, domain hosting, and databases. These packages include regular checks for the speed, security, and overall health of the client's software and associated systems. The cost of these packages may vary depending on the scope of work required, with costs ranging between $50 and $5000.<br />
                    <br />
                    If a client decides to cancel their monthly maintenance package, they will be responsible for any associated costs going forward. The client may cancel at any time. It is the client's responsibility to inform Big Web Labs of their desire to cancel the maintenance package via email to jd@bigweblabs.com or sean@bigweblabs.com.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Termination of Agreements</strong>
                    <div className="my-4"></div>
                    Both the Client and Big Web Labs have the right to terminate any Services Agreement for any reason, including the ending of services that are already underway. No refunds shall be offered, where a Service is deemed to have begun and is, for all intents and purposes, underway. If the Client cancels an account before the work is completed or site is live, a cancellation fee is retained per the Cancellation Fee and Refund Policy (SEE Cancellation Fee and Refund Policy). Client agrees that all fees incurred and billed prior to cancellation effective date are valid and Client agrees to pay. Upon request for termination of services the website will be removed. A back-up copy of the website/software is not maintained by Big Web Labs. In the event of non-payment after 30 days following the expiration of the Net-15 as defined in (Payments & Chargebacks), ownership of all works and intellectual property rights developed during the scope of work will be transferred to Big Web Labs permanently

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Client Approval</strong>
                    <div className="my-4"></div>
                    Client is responsible for testing the functionality of the website / software upon Big Web Labs’ request for approval, and notification that the website / software has been completed. This includes, but is not limited to, functionality of all website / software pages, programmed functionality, database, e-commerce store, payment functions, galleries, forums and other business software functionalities.<br />
                    <br />
                    Client website / software design may be posted live as soon as the website design is completed by Big Web Labs and full design milestone payment is received from Client to Big Web Labs. Additional features, such as custom database programming, flash programming, etc., will be added to the site as they are completed. The website / software may be posted live within 30 days from purchase if Big Web Labs is required to complete content and design without complete Client input or content from Client.<br />
                    <br />
                    The Client understands and agrees that if the Client does not respond within 5 business days to Big Web Labs’s notification or requests for missing information, the website / software, along with the functionality of the website / software and the services rendered, will be deemed to be approved by the Client, and the website / software will be taken live with the missing information “as-is” or “under construction”.<br />
                    <br />
                    In the event that Big Web Labs completes all of the work per the original sale and database write ups, Big Web Labs reserves the right to move the site live and deem the work to be completed without Client’s permission if Client will not give approval of the work.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Scope Change Requests Before and After Website / Software Goes Live</strong>
                    <div className="my-4"></div>
                    Big Web Labs understands that sometimes a client may wish to change the scope of their project after it has gone live. In such cases, Big Web Labs will work with the client to determine the best course of action.<br />
                    <br />
                    For minor changes to the website or software, such as aesthetic adjustments, these will typically be covered under the monthly maintenance package.<br />
                    <br />
                    However, for bigger changes that involve significant modifications to the website or software, a new scope of work will be required. This will involve creating a new service quote and services agreement, and the monthly charges for the project may be adjusted accordingly.<br />
                    <br />
                    Before the website or software goes live, any changes to the original scope of work that are not clearly stated in the original scope of work will also require a new scope of work, service quote, and services agreement. The monthly charges for the project will be adjusted accordingly to reflect any changes to the scope of work.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Independent Contractor Status</strong>
                    <div className="my-4"></div>
                    The Contractor, under the code of the Internal Revenue  Service (IRS), is an independent contractor, and neither the Contractor's employees or contract  personnel are, or shall be deemed, the Client's employees.<br />
                    <br />
                    In its capacity as an independent contractor, Contractor agrees and represents: Contractor has the right to perform services for others during the term of this Agreement; Contractor has the sole right to control and direct the means, manner, and method by which the Services required by this Agreement will be performed. Contractor shall select the routes taken, starting and  ending times, days of work, and order the work is performed; Contractor has the right to hire assistant(s) as subcontractors or to use employees to provide the Services required under this Agreement. Neither Contractor, nor the Contractor’s employees or personnel, shall be required to wear any uniforms provided by the Client; The Services required by this Agreement shall be performed by the Contractor, Contractor’s employees or personnel, and the Client will not hire, supervise, or pay assistants to help the Contractor; Neither Contractor nor Contractor’s  employees or personnel shall receive any training from the Client in the professional skills necessary to perform the Services required by this Agreement; and Neither the Contractor nor Contractor’s employees or personnel shall be required by the Client to devote full-time to the performance of the Services required by this Agreement.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Federal & State Taxes</strong>
                    <div className="my-4"></div>
                    Under this Agreement, the Client shall not be responsible for: Withholding FICA, Medicare, Social Security, or any other federal or state withholding taxes from the Contractor’s payments to employees or personnel or make payments on behalf of the Contractor; Make federal or state unemployment compensation contributions on the Contractor’s behalf; and the payment of all taxes incurred related to or while performing the Services under this Agreement, including all applicable income taxes and, if the Contractor is  not a corporation, all applicable self-employment taxes. Upon demand, the Contractor shall provide the Client with proof that such payments have been made.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Benefits of Contractor's Employees</strong>
                    <div className="my-4"></div>
                    The Contractor understands and agrees that they  are solely responsible for shall be liable to all benefits that are provided to their employees, including but not limited to, retirement plans, health insurance, vacation time-off, sick pay, personal leave, or any other benefit provided.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Workers' Compensation</strong>
                    <div className="my-4"></div>
                    The Contractor shall be responsible for providing all workers’ compensation insurance on behalf of their employees. If the Contractor hires employees to perform any work under this Agreement, the Contractor agrees to grant workers’ compensation coverage to the extent required by law. Upon request by the Client, the Contractor must provide  certificates proving workers’ compensation insurance at any time during the performance of the Service.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Copyright Notice</strong>
                    <div className="my-4"></div>
                    Copyright and other relevant intellectual property rights exist on all text relating to the Company’s services and the full content of this website.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Force Majeure</strong>
                    <div className="my-4"></div>
                    Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control of such party including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other natural or manmade eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform the other Party of the same and shall use all reasonable endeavors to comply with the terms and conditions of any Agreement contained herein.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Waiver</strong>
                    <div className="my-4"></div>
                    Failure of either Party to insist upon strict performance of any provision of this or any Agreement or the failure of either Party to exercise any right or remedy to which it, he or they are entitled hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any Agreement. No waiver of any of the provisions of this or any Agreement shall be effective unless it is expressly stated to be such and signed by both Parties.

                    <div className="my-4"></div>
                    <strong class="font-cooper-black-std">General Terms</strong>
                    <div class="my-4"></div>
                    <p>The laws of California govern these terms and conditions. By accessing this website and using our services, you consent to these terms and conditions and to the exclusive jurisdiction of the California courts for any disputes arising. If any provision of these terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these terms, which shall remain in full effect.</p>


                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Proprietary Information</strong>
                    <div className="my-4"></div>
                    Proprietary information, under this Agreement, shall include:<br />
                    <br />
                    The product of all work performed under this Agreement (“Work Product”), including without limitation all notes, reports, documentation, drawings, computer programs, inventions, creations, works, devices, models, work-in-progress and deliverables will be the sole property of the Client, and Contractor hereby assigns to the Client all right, title and interest therein, including  but not limited to all audiovisual, literary, moral rights and other copyrights, patent rights, trade secret rights and other proprietary rights therein. Contractor retains no right to use the Work  Product and agrees not to challenge the validity of the Client’s ownership in the Work Product;<br />
                    <br />
                    Contractor hereby assigns to the Client all right, title, and interest in any and all photographic images and videos or audio recordings made by the Client during Contractor’s work for them, including, but not limited to, any royalties, proceeds, or other benefits derived from such  photographs or recordings; and The Client will be entitled to use Contractor's name and/or  likeness in advertising and other materials.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Notification of Changes</strong>
                    <div className="my-4"></div>
                    The Company reserves the right to change these conditions from time to time as it sees fit and your continued use of the site will signify your acceptance of any adjustment to these terms. If there are any changes to our privacy policy, we will announce that these changes have been made on our home page and on other key pages on our site. If there are any changes in how we use our site customers’ Personally Identifiable Information, notification by e-mail or postal mail will be made to those affected by this change. Any changes to our privacy policy will be posted on our web site 30 days prior to these changes taking place. You are therefore advised to re-read this statement on a regular basis.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">No Partnership</strong>
                    <div className="my-4"></div>
                    This Agreement does not create a partnership relationship between the Client and the Contractor. Unless otherwise directed, the Contractor shall have no authority to enter into contracts on the Client's behalf or represent the Client in any manner.

                    <div className="my-4"></div>
                    <strong className="font-cooper-black-std">Contact:</strong>
                    <div className="my-4"></div>

                    For questions or concerns relating to these terms, we can be contacted at sean@bigweblabs.com or jd@bigweblabs.com</p>

                <div className="my-4"></div>
                <strong>These terms and conditions form part of the Agreement between the Client and ourselves. Your accessing of this website and/or undertaking of a booking or Agreement indicates your understanding, agreement to and acceptance, of the Disclaimer Notice and the full Terms and Conditions contained herein. Your statutory Consumer Rights are unaffected.</strong>
                <div className="my-8"></div>
            </div>
            <Footer />
        </motion.div>
    );
}

export default Terms;